<template>
	<div id="official">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的班级</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="meunTap(index)"
						:class="index == MeunIndex ? 'active' : ''"
						v-for="(item, index) in Meun"
						:key="index"
					>
						{{ item }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="u-f">
					<div class="right">
						<div class="title u-f-item u-f-jsb">
							<div>共{{ total }}条数据</div>
							<el-button size="small" @click="toAdd">发布公告</el-button>
						</div>
						<el-table :data="ArticleList" height="83%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
							<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
							<el-table-column label="序号" type="index" width="65" align="center"></el-table-column>
							<el-table-column prop="title" label="公告标题" align="center"></el-table-column>
							<el-table-column label="发布时间" align="center">
								<template slot-scope="scope">
									<div>{{$common.timeToDate(scope.row.createtime)}}</div>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="center" width="120">
								<template slot-scope="scope">
									<el-button @click="editArticle(scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="delcourse(scope.row.id)" type="text" size="small" style="color:red;">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<!-- <el-button type="danger" plain size="mini" @click="delUser">删除</el-button> -->
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			MeunIndex:6,
			Meun:['班级学生','班级课表','学生考勤','过程性记录','班级相册','班级视频','班级公告','班级风采','班级荣誉','出入记录'],
			headers:{
				token:window.localStorage.getItem("token")
			},
			total:0,
			page:1,
			limit:10,
			ArticleList:[],
			class_id:''
			
		};
	},
	mounted() {
		this.class_id = this.$route.query.id;
		this.getClassNotice()
	},
	methods: {
		toAdd(){
			this.$router.push({
				path: '/addClassNotice', 
				query: {
					class_id: this.class_id
				}
			})
		},
		meunTap(index){
			this.MeunIndex = index;
			if(index==4){
				this.$router.push({path:'/classPhoto',query:{id: this.class_id}})
			}else if(index==5){
				this.$router.push({path:'/classVideo',query:{id: this.class_id}})
			}else if(index==6){
				this.$router.push({path:'/classNotice',query:{id: this.class_id}})
			}else if(index==7){
				this.$router.push({path:'/classMien',query:{id: this.class_id}})
			}else if(index==8){
				this.$router.push({path:'/classHonor',query:{id: this.class_id}})
			}else if(index==9){
				this.$router.push({path:'/classAccess',query:{id: this.class_id}})
			}else{
				this.$router.push({path:'/myClassDetails',query:{id: this.class_id,index:index}})
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getClassNotice()
		},
		// 获取资讯列表
		getClassNotice(){
			let data = {
				page:this.page,
				pagesize:this.limit,
				filter:JSON.stringify({class_id:this.class_id})
			}
			this.$api.setting.getClassNotice(data).then(res => {
				if (res.data.code == 1) {
					this.ArticleList = res.data.data.rows;
					this.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 删除资讯
		delcourse(id){
			let _this = this;
			_this
				.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.setting
						.delClassNotice({
							id: id
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.page = 1;
								_this.getClassNotice();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		editArticle(e){
			this.$router.push({
				path: '/addClassNotice', 
				query: {
					id: e.id,
					class_id: this.class_id
				}
			})
		}
	}
};
</script>

<style lang="scss">
#official {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 70vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 50px;
				height: 50px;
				line-height: 50px;
				text-align: center;
			}
			.avatar {
				width: 50px;
				height: 50px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
